import cloneDeep from 'lodash/cloneDeep'

const invoiceData = {
    supplierInvoiceInvoiceNumber: null,
    supplierInvoiceNetValue: null,
    supplierInvoiceVatValue: null,
    supplierInvoiceGrossValue: null,
    commissionInvoiceInvoiceNumber: null,
    commissionInvoiceNetValue: null,
    commissionInvoiceVatValue: null,
    commissionInvoiceGrossValue: null,
    salesInvoiceInvoiceNumber: null,
    salesInvoiceNetValue: null,
    salesInvoiceVatValue: null,
    salesInvoiceGrossValue: null,
    supplierPaidDate: null,
    additionalSupplierInvoices: [],
    additionalCommissionInvoices: [],
    additionalSalesInvoices: [],
    requiresSeparateInvoice: false,
}

const isInvoiceDataEmpty = (data) =>
    Object.keys(invoiceData).every((key) => !data[key])

class OrderFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        if (isInvoiceDataEmpty(entity.invoiceData)) {
            entity.invoiceData = null
        }

        entity.preparationConfirmed = !!entity.preparationConfirmed

        for (let field of [
            'thirdPartyCode',
            'thirdPartyOrderId',
            'thirdPartyTrackingUrl',
        ]) {
            if (entity[field]) {
                entity[field] = String(entity[field])
            } else {
                entity[field] = null
            }
        }

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        if (!formData['refund']) formData['refund'] = {}
        if (!formData.invoiceData) formData.invoiceData = cloneDeep(invoiceData)
        if (formData.invoiceData?.supplierPaidDate) {
            formData.invoiceData.supplierPaidDate = formData.invoiceData.supplierPaidDate.substr(
                0,
                10
            )
        }

        return formData
    }
}

export default new OrderFormMapper()
