import ResponseHandler from '@/api/response/ResponseHandler'
import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import orderType from '@/store/type/orderType'
import apiClient from '@/api/ApiClient'
import Store from '@/store/store'
import getAdminSource from '@/services/GetAdminSource'

class OrdersApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/orders'
    }
    getBaseUrlV2() {
        return '/api/v1/orders/v2'
    }

    update(entity, id) {
        return ResponseHandler.handle(
            super.update(entity, id),
            orderType.mutations.SET_ACTIVE_ORDER
        )
    }

    getHistory(after, before) {
        return super
            .list({
                'requiredDeliveryTime[after]': after,
                'requiredDeliveryTime[before]': before,
            })
            .then((data) => {
                Store.dispatch(
                    orderType.actions.SET_HISTORY_ORDERS_LIST,
                    data['hydra:member']
                )
                return data
            })
    }

    approve(id, entity) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'approve'), entity, {
                params: getAdminSource(),
            })
        )
    }

    cancel(id, payload) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'cancel'), payload)
        )
    }
    confirm(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'preparation-confirm'))
        )
    }

    downloadLabel(restaurantId, ccId, cutoffTime) {
        let url = `/api/v1/cloud-canteen/label/view/${ccId}/${restaurantId}`
        if (cutoffTime) {
            url += `?cutoffTime=${cutoffTime}`
        }
        return ResponseHandler.handle(
            apiClient.get(url, {
                responseType: 'blob',
            })
        )
    }
    downloadLabelRegularOrder(orderId, token) {
        let url = `/public/order/${orderId}/label?token=${token}`
        return ResponseHandler.handle(
            apiClient.get(url, {
                responseType: 'blob',
            })
        )
    }

    refund(id, entity) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'refund'), entity)
        )
    }

    previewEmail(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'confirmation-email-preview'))
        )
    }

    mailSend(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'send-out/ordit'))
        )
    }

    getDeliveryJobDefaultValues(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'default-delivery-job-values'))
        )
    }
    removeDeliveryWarning(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clear-delivery-warnings'))
        )
    }

    downloadMealList(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'download/meal-info-list'), {
                responseType: 'blob',
            })
        )
    }

    downloadSummary(identifier, token, cutoffTime) {
        const params = {}
        if (token) {
            params.token = token
        }
        if (cutoffTime) {
            params.cutoffTime = cutoffTime
        }
        return ResponseHandler.handle(
            apiClient.get(`/api/v1/cloud-canteen/summary/${identifier}`, {
                responseType: 'blob',
                params,
            })
        )
    }

    reOrder(id, reason) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 're-order'), { reason })
        )
    }

    /**
     * @typedef AmountRefundPayload
     * @type {object}
     * @property {?number} amount - Amount to refund, null = full refund
     * @property {string} refundOnBehalf - Possible values: 'ordit', 'restaurant', 'logistics'
     * @property {?string} reason - Reason for refund
     */

    /**
     * @typedef ItemRefundItem
     * @type {object}
     * @property {number} item - Order item id
     * @property {number} quantity - Quantity to refund
     * @property {?string} reason - Reason for refund
     */

    /**
     * @typedef ItemRefundPayload
     * @type {object}
     * @property {ItemRefundItem[]} items - Items to refund
     * @property {string} refundOnBehalf  - Possible values: 'ordit', 'restaurant', 'logistics'
     * @property {?string} reason - Reason for refund
     */

    /**
     * @param {number} id
     * @param {(AmountRefundPayload|ItemRefundPayload)} payload
     * @returns {Promise}
     */
    refundOrder(id, payload) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'refund-order'), payload)
        )
    }

    /**
     * @param {number} id
     * @param {(AmountRefundPayload|ItemRefundPayload)} payload
     * @returns {Promise}
     */
    refundOrderCalculate(id, payload) {
        return ResponseHandler.handle(
            apiClient.put(
                this.getItemIri(id, 'refund-order/calculate'),
                payload
            )
        )
    }

    updateProofOfDelivery(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'update-proof-of-delivery'))
        )
    }

    queueDeliverectCancel(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'deliverect-cancel'))
        )
    }
}

export default new OrdersApiClient()
