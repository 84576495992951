<template>
    <overlay :show="loading">
        <div v-if="loaded">
            <v-input
                v-for="(code, idx) in codes"
                :key="idx"
                :error-messages="getRowErrorMessages(idx)"
                dense
                hide-details="auto"
            >
                <div class="d-flex w-100">
                    <v-row>
                        <v-col
                            v-for="key in keyOrder"
                            :key="`${idx}-${key}`"
                            :lg="colWidth"
                            cols="12"
                            class="py-0"
                        >
                            <v-textarea
                                v-if="
                                    config[key].availableExpenseCodes.length ===
                                    0
                                "
                                v-model="code.codes[key]"
                                v-validate.immediate="
                                    getValidators(config[key])
                                "
                                auto-grow
                                rows="1"
                                :label="config[key].title"
                                :name="`${key}-${idx}`"
                                :placeholder="
                                    config[key].expenseCodePlaceholder
                                "
                                :error="!getRowIsValid(idx)"
                                :error-messages="
                                    getErrorMessages(
                                        `${key}-${idx}`,
                                        config[key]
                                    )
                                "
                                :data-vv-as="config[key].title"
                            ></v-textarea>
                            <v-autocomplete
                                v-else-if="
                                    config[key].onlyFromList === true &&
                                    !isSuperAdmin
                                "
                                v-model="code.codes[key]"
                                v-validate.immediate="
                                    getValidators(config[key])
                                "
                                :label="config[key].title"
                                :name="`${key}-${idx}`"
                                :items="config[key].availableExpenseCodes"
                                :clearable="!config[key].isRequired"
                                :error="!getRowIsValid(idx)"
                                :error-messages="
                                    getErrorMessages(
                                        `${key}-${idx}`,
                                        config[key]
                                    )
                                "
                                :data-vv-as="config[key].title"
                            ></v-autocomplete>
                            <v-combobox
                                v-else
                                v-model="code.codes[key]"
                                v-validate.immediate="
                                    getValidators(config[key])
                                "
                                :label="config[key].title"
                                :name="`${key}-${idx}`"
                                :items="config[key].availableExpenseCodes"
                                :placeholder="
                                    config[key].expenseCodePlaceholder
                                "
                                :clearable="!config[key].isRequired"
                                :error="!getRowIsValid(idx)"
                                :error-messages="
                                    getErrorMessages(
                                        `${key}-${idx}`,
                                        config[key]
                                    )
                                "
                                :data-vv-as="config[key].title"
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <div v-if="codes.length > 1" class="d-flex ml-3">
                        <v-text-field
                            v-model="code.distribution"
                            v-validate="'decimal:2'"
                            class="allocation-input"
                            type="number"
                            :label="$t('labels.expense_codes.allocation')"
                            :name="`${idx}-distribution`"
                            :step="0.01"
                            :error="!isDistributionValid"
                            :error-messages="
                                errors.collect(`${idx}-distribution`)
                            "
                            :data-vv-as="$t('labels.expense_codes.allocation')"
                            @blur="checkDecimal(code, code.distribution)"
                        ></v-text-field>
                        <v-btn
                            icon
                            class="ml-2"
                            color="error"
                            @click="removeRow(idx)"
                        >
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-input>
            <div
                v-show="!isDistributionValid"
                class="distribution-error my-3 pa-2 red lighten-5 rounded error--text font-weight-bold"
            >
                <v-icon color="error">warning</v-icon>
                {{
                    $t('validations.messages.costcode-distribution', {
                        by: distributionDiff.toFixed(2),
                    })
                }}
            </div>
            <a
                class="d-inline-block success--text text-decoration-underline font-weight-medium text-uppercase py-2"
                style="letter-spacing: 0.0892857143em"
                @click="addRow"
                >{{ $t('actions.add') }}</a
            >
        </div>
        <div v-else style="min-height: 80px"></div>
    </overlay>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import AllowancesApiClient from '@/api/RestApi/CompanyAllowances'
import CloudcanteensApiClient from '@/api/RestApi/CompanyCloudcanteens'
import FormInput from '@/components/mixins/FormInput'
import AuthMixin from '@/components/mixins/AuthMixin'
import { getId } from '@/services/IdFromIri'
import Overlay from '@/components/overlay/Overlay.vue'

export default {
    components: {
        Overlay,
    },
    mixins: [FormInput, AuthMixin],
    provide() {
        return {
            formValidator: this.$validator,
        }
    },
    data: () => ({
        config: null,
        keyOrder: [],
        loading: false,
        loaded: false,
        codes: [],
        lastLoadIri: null,
        usesOldField: false,
    }),
    computed: {
        identifier() {
            return this.value?.identifier
        },
        colWidth() {
            return Math.floor(12 / this.keyOrder.length)
        },
        distributionSum() {
            let sum = 0
            for (let i = 0; i < this.codes.length; i++) {
                sum += this.distributionStrToInt(this.codes[i].distribution)
            }
            return sum / 100
        },
        distributionDiff() {
            return 100 - this.distributionSum
        },
        isDistributionValid() {
            return this.distributionSum === 100
        },
        rowValids() {
            return this.codes.map((code) => {
                const keys = Object.keys(code.codes)
                let valid = false
                for (let i = 0; i < keys.length; i++) {
                    if (code.codes[keys[i]]) {
                        valid = true
                        break
                    }
                }
                return valid
            })
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newItem) {
                let iri = this.getIri(newItem)
                if (newItem) {
                    if (!iri || iri !== this.lastLoadIri) {
                        this.lastLoadIri = iri
                        this.loadConfig(iri)
                    } else {
                        this.loaded = true
                    }

                    if (newItem.costCode) {
                        this.usesOldField = true
                        this.codes = [
                            {
                                codes: { default: newItem.costCode },
                                distribution: 100,
                            },
                        ]
                    } else {
                        this.usesOldField = false
                        this.codes = cloneDeep(newItem.expenseCode || [])
                        if (!this.codes.length) {
                            this.codes.push({
                                codes: { default: '' },
                                distribution: 100,
                            })
                        }
                    }
                } else {
                    this.usesOldField = false
                    this.codes = []
                    this.loaded = false
                }

                this.codes.forEach((code) => {
                    code.distribution = Number(code.distribution).toFixed(2)
                })
            },
        },
        codes: {
            deep: true,
            handler() {
                if (!this.value) {
                    return
                }
                const valueClone = cloneDeep(this.value)
                this.fillItemCodesToSave(valueClone)

                if (!isEqual(this.value, valueClone)) {
                    this.$emit('input', valueClone)
                }
            },
        },
    },
    methods: {
        addRow() {
            const lastcode = this.codes[this.codes.length - 1]

            if (!lastcode) {
                const newcode = {
                    codes: {},
                    distribution: 100,
                }

                this.keyOrder.forEach((key) => {
                    newcode.codes[key] = ''
                })

                this.codes.push(newcode)
            } else {
                this.codes.push(cloneDeep(lastcode))
            }

            this.redistributeAllocations()
        },
        getIri(item) {
            if (item?.selectedExpenseRuleId) {
                return `allowance/${item.selectedExpenseRuleId}`
            } else if (item?.cloudcanteen?.['@id']) {
                return `cloudcanteen/${getId(item.cloudcanteen['@id'])}`
            }
            return null
        },
        loadConfig(iri) {
            this.loading = true

            if (iri && iri.startsWith('allowance/')) {
                let id = this.value.selectedExpenseRuleId
                AllowancesApiClient.get(id).then((res) => {
                    this.parseConfig(res)
                })
            } else if (iri && iri.startsWith('cloudcanteen/')) {
                let id = this.value.cloudcanteen['@id']
                CloudcanteensApiClient.get(getId(id)).then((res) => {
                    this.parseConfig(res)
                })
            } else {
                this.parseConfig({
                    expenseCodeTitle: this.$t('defaults.expense_code_title'),
                    expenseCodeList: [],
                    expenseCodeRequired: 0,
                    expenseCodeOnlyFromList: false,
                    expenseCodeRegexValidation: null,
                    expenseCodeRegexValidationError: null,
                    expenseCodePlaceholder: null,
                    additionalExpenseCodes: [],
                })
            }
        },
        parseConfig(res) {
            let config = {
                default: {
                    title: res.expenseCodeTitle,
                    availableExpenseCodes: res.expenseCodeList,
                    isRequired: res.expenseCodeRequired === 2,
                    onlyFromList: res.expenseCodeOnlyFromList,
                    expenseCodeRegexValidation: res.expenseCodeRegexValidation,
                    expenseCodeRegexValidationError:
                        res.expenseCodeRegexValidationError,
                    expenseCodePlaceholder: res.expenseCodePlaceholder,
                },
            }
            let keyOrder = ['default']

            res.additionalExpenseCodes.forEach((code) => {
                config[code.id] = code
                keyOrder.push(code.id)
            })

            this.config = config
            this.keyOrder = keyOrder

            this.$nextTick(() => {
                this.$validator.validate()
                this.loading = false
                this.loaded = true
            })
        },
        redistributeAllocations() {
            let remaining = 10000
            let step = Math.floor(remaining / this.codes.length)
            for (let i = 0; i < this.codes.length; i++) {
                if (i < this.codes.length - 1) {
                    this.codes[i].distribution = (step / 100).toFixed(2)
                    remaining -= step
                } else {
                    this.codes[i].distribution = (remaining / 100).toFixed(2)
                }
            }
        },
        removeRow(idx) {
            this.codes.splice(idx, 1)
            this.redistributeAllocations()
        },
        getValidators(config) {
            let regex = config.expenseCodeRegexValidation

            if (typeof regex === 'string' && regex.length > 0) {
                // const delimiter = regex.charAt(0)
                // const delimiterEndIdx = regex.lastIndexOf(delimiter)
                // const flags = regex.substring(delimiterEndIdx + 1)
                // const pattern = regex.substring(1, delimiterEndIdx)

                regex = new RegExp(regex)
            } else {
                regex = false
            }

            return {
                required: config.isRequired,
                regex,
            }
        },
        getErrorMessages(name, config) {
            return this.errors
                .collect(name, undefined, false)
                .map((error) =>
                    error.rule === 'regex' &&
                    config.expenseCodeRegexValidationError
                        ? config.expenseCodeRegexValidationError
                        : error.msg
                )
        },
        getRowIsValid(rowIndex) {
            return this.rowValids.length === 1 || this.rowValids[rowIndex]
        },
        getRowErrorMessages(rowIndex) {
            return this.getRowIsValid(rowIndex)
                ? []
                : this.$t('validations.messages.costcode-emptyrow')
        },
        checkDecimal(field, value) {
            if (
                typeof value === 'string' &&
                (value.indexOf('.') === -1 ||
                    value.indexOf('.') > value.length - 3)
            ) {
                field.distribution = Number(value).toFixed(2)
            }
        },
        distributionStrToInt(field) {
            let fieldCopy = String(field)
            const decimal = fieldCopy.indexOf('.')
            if (decimal !== -1) {
                let decimals = fieldCopy.length - 1 - decimal
                if (decimals > 2) {
                    fieldCopy = fieldCopy.substring(0, decimal + 3)
                    decimals = 2
                }
                fieldCopy = fieldCopy.replace('.', '')

                return fieldCopy * Math.pow(10, 2 - decimals)
            }

            return parseInt(field) * 100
        },
        fillItemCodesToSave(item) {
            if (this.usesOldField) {
                if (this.codes.length === 1) {
                    item.costCode = this.codes[0].codes.default
                    if (!item.costCode) {
                        item.costCode = null
                    }
                } else {
                    item.costCode = null
                    item.expenseCode = cloneDeep(this.codes)
                }
            } else {
                if (
                    this.codes.length === 1 &&
                    Object.keys(this.codes[0].codes).length === 1 &&
                    typeof this.codes[0].codes.default !== 'undefined' &&
                    !this.codes[0].codes.default
                ) {
                    item.expenseCode = []
                } else {
                    item.expenseCode = cloneDeep(this.codes)
                }
            }

            item.expenseCode.forEach((code) => {
                code.distribution = parseFloat(code.distribution)
            })
        },
    },
}
</script>

<style>
.allocation-input {
    width: 80px;
}
.allocation-input .v-text-field__slot input {
    text-align: right;
}
</style>
