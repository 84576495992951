<template>
    <div class="d-flex align-center py-1" :class="{ truncated: truncateCodes }">
        <div class="flex-grow-1">
            <template v-for="(expenseCode, index) in expenseCodes">
                <div
                    v-for="(code, key, idx) in expenseCode.codes"
                    :key="`${index}-${key}`"
                    class="d-flex align-center justify-space-between"
                    :class="{ 'mt-1': index > 0 }"
                >
                    <div
                        v-if="!code || code.length < 20"
                        :class="{
                            'font-weight-medium': idx === 0,
                            'text-caption g-73--text': idx !== 0,
                        }"
                        class="text-no-wrap text-truncate costcode-block"
                    >
                        {{ code }}
                    </div>
                    <v-tooltip v-else bottom>
                        <template #activator="{ on }">
                            <div
                                :class="{
                                    'font-weight-medium': idx === 0,
                                    'text-caption g-73--text': idx !== 0,
                                }"
                                class="text-no-wrap text-truncate costcode-block"
                                v-on="on"
                            >
                                {{ code }}
                            </div>
                        </template>
                        <span>{{ code }}</span>
                    </v-tooltip>
                    <div
                        v-if="expenseCodes.length > 1 && idx === 0"
                        class="ml-2 flex-grow-0"
                    >
                        <v-chip label small class="px-1">
                            {{ formatDistribution(expenseCode.distribution) }}%
                        </v-chip>
                    </div>
                </div>
            </template>
        </div>
        <div v-if="showArrow" class="flex-grow-0 flex-shrink-0 ml-1">
            <v-btn icon plain @click="$emit('click')">
                <v-icon>keyboard_arrow_right</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
    props: {
        item: null,
        showArrow: {
            type: Boolean,
            default: true,
        },
        truncateCodes: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        expenseCodes() {
            return this.item.costCode
                ? [
                      {
                          codes: { 0: this.item.costCode },
                          distribution: 100,
                      },
                  ]
                : (this.item.expenseCode || []).map((code) => {
                      const clone = cloneDeep(code)
                      const defaultCode = clone.codes?.default
                      delete clone.codes.default

                      const codes = defaultCode
                          ? {
                                0: defaultCode,
                                ...clone.codes,
                            }
                          : clone.codes

                      clone.codes = codes
                      return clone
                  })
        },
    },
    methods: {
        formatDistribution(distribution) {
            return Number(distribution).toFixed(2)
        },
    },
}
</script>

<style lang="scss">
.truncated .costcode-block {
    max-width: 120px;
}
</style>
