var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center py-1",class:{ truncated: _vm.truncateCodes }},[_c('div',{staticClass:"flex-grow-1"},[_vm._l((_vm.expenseCodes),function(expenseCode,index){return _vm._l((expenseCode.codes),function(code,key,idx){return _c('div',{key:(index + "-" + key),staticClass:"d-flex align-center justify-space-between",class:{ 'mt-1': index > 0 }},[(!code || code.length < 20)?_c('div',{staticClass:"text-no-wrap text-truncate costcode-block",class:{
                        'font-weight-medium': idx === 0,
                        'text-caption g-73--text': idx !== 0,
                    }},[_vm._v(" "+_vm._s(code)+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-no-wrap text-truncate costcode-block",class:{
                                'font-weight-medium': idx === 0,
                                'text-caption g-73--text': idx !== 0,
                            }},on),[_vm._v(" "+_vm._s(code)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(code))])]),(_vm.expenseCodes.length > 1 && idx === 0)?_c('div',{staticClass:"ml-2 flex-grow-0"},[_c('v-chip',{staticClass:"px-1",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.formatDistribution(expenseCode.distribution))+"% ")])],1):_vm._e()],1)})})],2),(_vm.showArrow)?_c('div',{staticClass:"flex-grow-0 flex-shrink-0 ml-1"},[_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.$emit('click')}}},[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }