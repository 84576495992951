<template lang="pug">
.d-flex
    edit-button(
        v-if="canConfirm"
        :tooltip="$t('labels.approve_order')"
        @execute='confirmOrderModal'
        icon='check_circle' )
    edit-button(
        v-if="canCancel"
        :tooltip="$t('labels.cancel_order')"
        @execute='orderCancelConfirm = true'
        icon='cancel' )
    edit-button(
        v-if='item.deliveryJob'
        :tooltip="$t('labels.pedalme_tooltip')"
        @execute="pedalmeDialog = true"
        icon='pedal_bike')
    edit-button(
        v-if="canSend"
        :tooltip="$t('labels.delivery_send_out')"
        @execute='deliverySendOutModal(item)'
        icon='send' )
    edit-button(
        v-if='item.canYouCreateDeliveryJob && !isCanteen'
        :tooltip="$t('labels.create_delivery_job')"
        :to='{name: routeType.DELIVERY_CREATE,params: { id: item.id },}'
        icon='swap_horiz' )
    edit-button(
        v-if="!isCanteenSuborder"
        :tooltip="$t('labels.order_mail_resend')"
        @execute='resendMailModal'
        icon='outgoing_mail' )
    edit-button(
        :tooltip="$t('labels.order_mail_preview')"
        :to='{name: routeType.ORDER_PREVIEW_EMAIL,params: { id: item.id },}'
        icon='email'
        target='_blank')
    strong-confirm-modal(
        v-model="strongConfirm"
        :title="strong.title"
        :text="strong.text"
        :processing="processing"
        :approve-color="strong.approveColor"
        @onApprove="strongAction"
        @onCancel="strongConfirm = false")
    confirm-modal(
        :value="confirmDeliverSendOutModal"
        :text="$t('orders.do_you_want_send_out_this_order')"
        :processing="processing"
        @onApprove="setDeliverySendOut()"
        @onCancel="cancelDeliverySendOut")
    pedalme-delivery-modal(
        v-if="item.deliveryJob"
        v-model="pedalmeDialog"
        :delivery-job="item.deliveryJob"
        @onApprove="sendPedalme"
    )
    order-cancel-modal(
        v-model="orderCancelConfirm"
        :order-id="item.id"
        :has-firm-payment="item.priceSumFirm !== '0.00'"
        @order-cancelled="onCancelOrder"
    )
</template>

<script>
import routeType from '@/router/routeType'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import StrongConfirmModal from '@/components/confirm/StrongConfirmModal'
import PedalmeDeliveryModal from '@/components/pages/canteen/PedalmeDeliveryModal'
import OrderCancelModal from '@/components/pages/orders/OrderCancelModal'
import EditButton from '@/components/table/buttons/EditButton'
import OrdersApi from '@/api/RestApi/OrdersApiClient'
import DeliveriesApi from '@/api/RestApi/DeliveriesApiClient'
import Notification from '@/services/Notification/Notification'

const modalBase = {
    title: '',
    text: '',
    action: '',
    approveColor: null,
}

export default {
    components: {
        ConfirmModal,
        StrongConfirmModal,
        PedalmeDeliveryModal,
        OrderCancelModal,
        EditButton,
    },
    props: {
        item: Object,
        updateTableItems: Function,
    },
    data() {
        return {
            confirmDeliverSendOutModal: false,
            confirmCancelOrderModal: false,
            pedalmeDialog: false,
            deliverySendOutItem: null,
            cancelOrderItem: null,
            processing: false,
            routeType: routeType,
            strongConfirm: false,
            strong: { ...modalBase },
            orderCancelConfirm: false,
        }
    },
    computed: {
        isCanteen() {
            return (
                Boolean(this.item.cloudcanteen) ||
                Boolean(this.item.residentialcanteen)
            )
        },
        canConfirm() {
            return (
                this.item.status !== 'approved' &&
                this.item.status !== 'init-payment' &&
                this.item.isDeliveryProcessed &&
                !this.isCanteen
            )
        },
        canCancel() {
            return (
                this.item.status !== 'cancelled' &&
                this.item.status !== 'cancelled-after-approval' &&
                this.item.status !== 'init-payment' &&
                this.item.isDeliveryProcessed &&
                !this.isCanteen
            )
        },
        canSend() {
            return (
                this.item.deliveryJob &&
                this.item.deliveryJob.id &&
                this.item.deliveryJob.status === 'initialized' &&
                this.item.isDeliveryProcessed &&
                !this.isCanteen
            )
        },
        isCanteenSuborder() {
            return this.isCanteen && Boolean(this.item.userProfile)
        },
    },
    methods: {
        strongAction() {
            this[this.strong.action]().then(() => {
                this.strongConfirm = false
            })
        },

        confirmOrderModal() {
            this.strong = {
                ...modalBase,
                title: this.$t('labels.approve_order'),
                text: this.$t(
                    'orders.do_you_really_want_to_approve_this_order',
                    { orderIdentifier: this.item.identifier }
                ),
                action: 'confirmOrder',
            }
            this.strongConfirm = true
        },

        confirmOrder() {
            return OrdersApi.approve(this.item.id)
        },

        onCancelOrder() {
            Notification.success(
                this.item.identifier,
                this.$t('notifications.entity_deleted.message', {
                    name: this.item.identifier,
                })
            )
        },

        resendMailModal() {
            this.strong = {
                ...modalBase,
                title: this.$t('labels.order_mail_resend'),
                text: this.$t(
                    'orders.do_you_really_want_to_resend_this_order_mail',
                    { orderIdentifier: this.item.identifier }
                ),
                action: 'resendMail',
            }
            this.strongConfirm = true
        },

        resendMail() {
            return OrdersApi.mailSend(this.item.id).then(() => {
                Notification.success(
                    this.item.identifier,
                    this.$t('notifications.order_mail_resent.message')
                )
            })
        },

        deliverySendOutModal(item) {
            this.confirmDeliverSendOutModal = true
            this.deliverySendOutItem = item.deliveryJob.id
        },

        setDeliverySendOut() {
            this.processing = true
            DeliveriesApi.sendOutDeliveryJob(this.deliverySendOutItem)
                .then(() => {
                    Notification.success(
                        this.deliverySendOutItem,
                        this.$t('notifications.order_delivery_sent_out.message')
                    ),
                        (this.confirmDeliverSendOutModal = false)
                    this.updateTableItems()
                })
                .catch((reason) => {
                    Notification.error('order send out error', reason)
                })
                .finally(() => {
                    this.processing = false
                })
        },

        cancelDeliverySendOut() {
            this.confirmDeliverSendOutModal = false
            this.deliverySendOutItem = null
        },

        sendPedalme(pickupTime, selection, size) {
            DeliveriesApi.sendToPedalme(pickupTime, selection, size)
                .then(() => {
                    Notification.success(
                        this.$t('notifications.pedalme.success')
                    )
                })
                .catch((err) => {
                    let message = err.response?.data?.errors?.join(';')
                    Notification.error(
                        this.$t('notifications.pedalme.fail', {
                            message,
                        })
                    )
                })
                .finally(() => {
                    this.pedalmeDialog = false
                })
        },
    },
}
</script>

<style scoped lang="scss"></style>
