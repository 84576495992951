var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('overlay',{attrs:{"show":_vm.loading}},[(_vm.loaded)?_c('div',[_vm._l((_vm.codes),function(code,idx){return _c('v-input',{key:idx,attrs:{"error-messages":_vm.getRowErrorMessages(idx),"dense":"","hide-details":"auto"}},[_c('div',{staticClass:"d-flex w-100"},[_c('v-row',_vm._l((_vm.keyOrder),function(key){return _c('v-col',{key:(idx + "-" + key),staticClass:"py-0",attrs:{"lg":_vm.colWidth,"cols":"12"}},[(
                                _vm.config[key].availableExpenseCodes.length ===
                                0
                            )?_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate.immediate",value:(
                                _vm.getValidators(_vm.config[key])
                            ),expression:"\n                                getValidators(config[key])\n                            ",modifiers:{"immediate":true}}],attrs:{"auto-grow":"","rows":"1","label":_vm.config[key].title,"name":(key + "-" + idx),"placeholder":_vm.config[key].expenseCodePlaceholder,"error":!_vm.getRowIsValid(idx),"error-messages":_vm.getErrorMessages(
                                    (key + "-" + idx),
                                    _vm.config[key]
                                ),"data-vv-as":_vm.config[key].title},model:{value:(code.codes[key]),callback:function ($$v) {_vm.$set(code.codes, key, $$v)},expression:"code.codes[key]"}}):(
                                _vm.config[key].onlyFromList === true &&
                                !_vm.isSuperAdmin
                            )?_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate.immediate",value:(
                                _vm.getValidators(_vm.config[key])
                            ),expression:"\n                                getValidators(config[key])\n                            ",modifiers:{"immediate":true}}],attrs:{"label":_vm.config[key].title,"name":(key + "-" + idx),"items":_vm.config[key].availableExpenseCodes,"clearable":!_vm.config[key].isRequired,"error":!_vm.getRowIsValid(idx),"error-messages":_vm.getErrorMessages(
                                    (key + "-" + idx),
                                    _vm.config[key]
                                ),"data-vv-as":_vm.config[key].title},model:{value:(code.codes[key]),callback:function ($$v) {_vm.$set(code.codes, key, $$v)},expression:"code.codes[key]"}}):_c('v-combobox',{directives:[{name:"validate",rawName:"v-validate.immediate",value:(
                                _vm.getValidators(_vm.config[key])
                            ),expression:"\n                                getValidators(config[key])\n                            ",modifiers:{"immediate":true}}],attrs:{"label":_vm.config[key].title,"name":(key + "-" + idx),"items":_vm.config[key].availableExpenseCodes,"placeholder":_vm.config[key].expenseCodePlaceholder,"clearable":!_vm.config[key].isRequired,"error":!_vm.getRowIsValid(idx),"error-messages":_vm.getErrorMessages(
                                    (key + "-" + idx),
                                    _vm.config[key]
                                ),"data-vv-as":_vm.config[key].title},model:{value:(code.codes[key]),callback:function ($$v) {_vm.$set(code.codes, key, $$v)},expression:"code.codes[key]"}})],1)}),1),(_vm.codes.length > 1)?_c('div',{staticClass:"d-flex ml-3"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('decimal:2'),expression:"'decimal:2'"}],staticClass:"allocation-input",attrs:{"type":"number","label":_vm.$t('labels.expense_codes.allocation'),"name":(idx + "-distribution"),"step":0.01,"error":!_vm.isDistributionValid,"error-messages":_vm.errors.collect((idx + "-distribution")),"data-vv-as":_vm.$t('labels.expense_codes.allocation')},on:{"blur":function($event){return _vm.checkDecimal(code, code.distribution)}},model:{value:(code.distribution),callback:function ($$v) {_vm.$set(code, "distribution", $$v)},expression:"code.distribution"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeRow(idx)}}},[_c('v-icon',[_vm._v("delete")])],1)],1):_vm._e()],1)])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isDistributionValid),expression:"!isDistributionValid"}],staticClass:"distribution-error my-3 pa-2 red lighten-5 rounded error--text font-weight-bold"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("warning")]),_vm._v(" "+_vm._s(_vm.$t('validations.messages.costcode-distribution', { by: _vm.distributionDiff.toFixed(2), }))+" ")],1),_c('a',{staticClass:"d-inline-block success--text text-decoration-underline font-weight-medium text-uppercase py-2",staticStyle:{"letter-spacing":"0.0892857143em"},on:{"click":_vm.addRow}},[_vm._v(_vm._s(_vm.$t('actions.add')))])],2):_c('div',{staticStyle:{"min-height":"80px"}})])}
var staticRenderFns = []

export { render, staticRenderFns }