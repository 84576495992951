<template>
    <modal-dialog
        :value="!!item"
        :ok-text="$t('actions.save')"
        :max-width="800"
        :show-overlay="loading"
        :ok-disabled="!isValid || loading"
        cancel-button
        scrollable
        scroll-content-height="80vh"
        @cancel="cancel"
        @ok="ok"
    >
        <v-form v-model="isValid">
            <multi-cost-code-form-part
                v-model="model"
            ></multi-cost-code-form-part>
        </v-form>
    </modal-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import OrdersApiClient from '@/api/RestApi/OrdersApiClient'
import ModalDialog from '@/components/dialog/ModalDialog.vue'
import MultiCostCodeFormPart from '../parts/MultiCostCodeFormPart.vue'

export default {
    components: { ModalDialog, MultiCostCodeFormPart },
    props: {
        item: null, // order
        sendRequest: {
            type: Boolean,
            default: true,
        },
    },
    provide() {
        return {
            formValidator: this.$validator,
        }
    },
    data: () => ({
        isValid: false,
        loading: false,
        model: null,
    }),
    watch: {
        item: {
            immediate: true,
            handler(value) {
                this.model = cloneDeep(value)
            },
        },
    },
    methods: {
        cancel() {
            this.$emit('close')
        },
        ok() {
            this.loading = true

            // wait .5 sec to ensure all changes came into effect
            setTimeout(() => {
                this.$validator.validate().then((isValid) => {
                    if (!isValid) {
                        this.loading = false
                        return
                    }

                    if (!this.sendRequest) {
                        this.$emit('update:item', this.model)
                        this.$emit('close')
                    } else {
                        OrdersApiClient.update(this.model, this.model.id)
                            .then(() => {
                                this.$emit('update:item', this.model)
                                this.$emit('saved')
                                this.$emit('close')
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                })
            }, 500)
        },
    },
}
</script>
