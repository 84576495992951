<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        deliveries: Boolean,
    },
    computed: {
        isSame() {
            return this.item.deliveryWindowFrom === this.item.deliveryWindowTo
        },
    },
}
</script>

<template lang="pug">
    div
        div
        span(v-if="isSame") {{ item.deliveryWindowFrom | transformDate }}
        span(v-else)
            | {{ item.deliveryWindowFrom | transformDate }} -
            | {{ item.deliveryWindowTo | transformDate(0, true) }}
</template>
