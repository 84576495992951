import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class NotificationsApiClient {
    getBaseUrl() {
        return '/api/v1/notification'
    }
    register(token) {
        return ResponseHandler.handle(
            apiClient.post(this.getBaseUrl() + '/register', {
                deviceToken: token,
            })
        )
    }
    test() {
        return ResponseHandler.handle(
            apiClient.get(`api/v1/test-push-notification/new-order/50395`)
        )
    }
    testApprove() {
        return ResponseHandler.handle(
            apiClient.get(`api/v1/test-push-notification/order-accepted/50395`)
        )
    }
    testDirectToCurrentUser() {
        return ResponseHandler.handle(
            apiClient.get(`api/v1/test-push-notification/send`)
        )
    }
    testDirectToUser(userId) {
        return ResponseHandler.handle(
            apiClient.post(`api/v1/test-push-notification/send-to-user`, {
                userId,
            })
        )
    }
    testUser(type, postData = {}) {
        return ResponseHandler.handle(
            apiClient.post(
                `api/v1/test-push-notification/user/${type}`,
                postData
            )
        )
    }
    testRestaurant(type, postData = {}) {
        return ResponseHandler.handle(
            apiClient.post(
                `api/v1/test-push-notification/restaurant/${type}`,
                postData
            )
        )
    }
}

export default new NotificationsApiClient()
