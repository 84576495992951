import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'
import selectapiType from '@/store/type/selectapiType'
import Store from '@/store/store'
import qs from 'qs'

class AbstractRestApi {
    getBaseUrl() {
        return ''
    }
    getBaseUrlV2() {
        return null
    }

    list(query, options) {
        const url = this.getBaseUrlV2()
            ? this.getBaseUrlV2()
            : this.getBaseUrl()
        let requestConfig = {
            params: query,
            paramsSerializer: (params) =>
                qs.stringify(params, {
                    encode: true,
                    arrayFormat: 'brackets',
                }),
        }

        if (options && options.signal) {
            requestConfig.signal = options.signal
        }

        return ResponseHandler.handle(apiClient.get(url, requestConfig))
    }

    create(entity, headers) {
        let config

        if (headers) {
            config = {}
            config['headers'] = headers
        }

        return ResponseHandler.handle(
            apiClient.post(this.getBaseUrl(), entity, headers)
        ).then(this.refreshSelectapiStore.bind(this))
    }

    update(entity, id) {
        return ResponseHandler.handle(
            apiClient.put(this.getBaseUrl() + '/' + id, entity)
        ).then(this.refreshSelectapiStore.bind(this))
    }

    get(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getBaseUrl() + '/' + id)
        )
    }

    delete(id) {
        return ResponseHandler.handle(
            apiClient.delete(this.getBaseUrl() + '/' + id)
        )
    }

    getRelatedSelectApi() {
        return null
    }

    refreshSelectapiStore(data) {
        if (this.getRelatedSelectApi()) {
            Store.dispatch(
                selectapiType.getActionName(
                    this.getRelatedSelectApi(),
                    selectapiType.actions.REFRESH
                )
            )
        }

        return data
    }

    getItemIri(id, action) {
        return action
            ? this.getBaseUrl() + '/' + id + '/' + action
            : this.getBaseUrl() + '/' + id
    }
}

export default AbstractRestApi
