import routeType from '@/router/routeType'
import paramType from '@/router/paramType'
import {
    ROLE_ADMIN,
    ROLE_RESTAURANT,
    ROLE_COMPANY,
    ROLE_EXTRA_BILLING_ACCESS,
} from '@/enum/roles'

export default {
    computed: {
        menu() {
            return [
                {
                    title: 'menu.orders',
                    icon: 'shopping_bag',
                    route: { name: routeType.ORDER_LIST },
                },
                {
                    title: 'menu.deliveries',
                    icon: 'two_wheeler',
                    roles: [ROLE_ADMIN],
                    route: { name: routeType.DELIVERY_LIST },
                },
                {
                    title: 'menu.canteens',
                    icon: 'brunch_dining',
                    roles: [ROLE_ADMIN],
                    route: { name: routeType.CANTEEN_LIST },
                },
                {
                    title: 'menu.users',
                    icon: 'group',
                    route: { name: routeType.USER_LIST },
                },
                {
                    title: 'menu.companies',
                    icon: 'business',
                    route: { name: routeType.COMPANY_LIST },
                },
                {
                    title: 'menu.company_details',
                    icon: 'edit',
                    route: { name: routeType.COMPANY_SELF_EDIT },
                },
                {
                    title: 'menu.company_allowances',
                    icon: 'list_alt',
                    roles: [ROLE_COMPANY],
                    route: {
                        name: routeType.COMPANY_ALLOWANCE_LIST,
                        params: {
                            companyId: this.companyId,
                        },
                    },
                },
                {
                    title: 'menu.company_addresses',
                    icon: 'location_pin',
                    roles: [ROLE_COMPANY],
                    route: {
                        name: routeType.COMPANY_ADDRESS_LIST,
                        params: {
                            companyId: this.companyId,
                        },
                    },
                },
                {
                    title: 'menu.company_employees',
                    icon: 'group',
                    roles: [ROLE_COMPANY],
                    route: {
                        name: routeType.COMPANY_EMPLOYEES,
                        params: {
                            companyId: this.companyId,
                        },
                    },
                },
                {
                    title: 'menu.teams',
                    icon: 'groups',
                    roles: [ROLE_COMPANY],
                    route: {
                        name: routeType.COMPANY_TEAMS_LIST,
                        params: {
                            companyId: this.companyId,
                        },
                    },
                },
                {
                    title: 'menu.canteen_calendar',
                    icon: 'calendar_month',
                    roles: [ROLE_COMPANY],
                    route: {
                        name: routeType.COMPANY_CANTEEN_CALENDAR,
                    },
                },
                {
                    title: 'menu.restaurants',
                    icon: 'restaurant',
                    route: { name: routeType.RESTAURANT_LIST },
                    roles: [ROLE_ADMIN],
                },
                {
                    title: 'menu.menu',
                    icon: 'restaurant',
                    route: {
                        name: routeType.RESTAURANT_EDIT,
                        params: {
                            id: this?.restaurant?.id,
                            page: paramType.RESTAURANT_MENU,
                        },
                    },
                    roles: [ROLE_RESTAURANT],
                },
                {
                    title: 'menu.business_hours',
                    icon: 'schedule',
                    route: {
                        name: routeType.RESTAURANT_EDIT,
                        params: {
                            id: this?.restaurant?.id,
                            page: paramType.RESTAURANT_BUSINESS_HOURS,
                        },
                    },
                    roles: [ROLE_RESTAURANT],
                },
                {
                    title: 'menu.brands',
                    icon: 'branding_watermark',
                    route: { name: routeType.BRAND_LIST },
                    roles: [ROLE_ADMIN],
                },
                {
                    title: 'menu.promotions',
                    icon: 'redeem',
                    roles: [ROLE_ADMIN],
                    children: [
                        {
                            title: 'menu.discounts',
                            route: { name: routeType.PROMOTION_LIST },
                        },
                        {
                            title: 'menu.coupons',
                            route: { name: routeType.COUPON_LIST },
                        },
                        {
                            title: 'menu.promo_banners',
                            route: { name: routeType.PROMO_BANNERS_LIST },
                        },
                    ],
                },
                {
                    title: 'menu.translations',
                    icon: 'language',
                    roles: [ROLE_ADMIN],
                    route: { name: routeType.TRANSLATION_LIST },
                },
                {
                    title: 'menu.invoices',
                    icon: 'article',
                    roles: [ROLE_ADMIN],
                    route: { name: routeType.INVOICES_DASHBOARD },
                },
                {
                    title: 'menu.custom_export',
                    icon: 'grading',
                    roles: [ROLE_ADMIN],
                    custom_export_edit_required: true,
                    children: [
                        {
                            title: 'menu.custom_export_category',
                            icon: 'grading',
                            route: {
                                name: routeType.CUSTOM_EXPORT_CATEGORY_LIST,
                            },
                        },
                        {
                            title: 'menu.custom_export_edit',
                            icon: 'grading',
                            route: { name: routeType.CUSTOM_EXPORT_LIST },
                        },
                        {
                            title: 'menu.custom_export',
                            icon: 'grading',
                            route: { name: routeType.CUSTOM_EXPORT_SHOW },
                        },
                        {
                            title: 'menu.custom_update',
                            icon: 'grading',
                            route: { name: routeType.CUSTOM_UPDATE_SHOW },
                        },
                    ],
                },
                {
                    title: 'menu.custom_export',
                    icon: 'grading',
                    roles: [ROLE_ADMIN],
                    custom_export_edit_required: false,
                    children: [
                        {
                            title: 'menu.custom_export_edit',
                            icon: 'grading',
                            route: { name: routeType.CUSTOM_EXPORT_LIST },
                        },
                        {
                            title: 'menu.custom_export',
                            icon: 'grading',
                            route: { name: routeType.CUSTOM_EXPORT_SHOW },
                        },
                        {
                            title: 'menu.custom_update',
                            icon: 'grading',
                            route: { name: routeType.CUSTOM_UPDATE_SHOW },
                        },
                    ],
                },
                {
                    title: 'menu.invoices',
                    icon: 'article',
                    roles: [ROLE_RESTAURANT, ROLE_EXTRA_BILLING_ACCESS],
                    route: { name: routeType.INVOICES_DASHBOARD },
                },
                {
                    title: 'menu.invoices',
                    icon: 'article',
                    roles: [ROLE_COMPANY, ROLE_EXTRA_BILLING_ACCESS],
                    route: {
                        name: routeType.INVOICES_DASHBOARD,
                        params: {
                            companyId: this.companyId,
                        },
                    },
                },
                {
                    title: 'menu.tags',
                    icon: 'tag',
                    roles: [ROLE_ADMIN],
                    children: [
                        {
                            title: 'menu.tag_group',
                            icon: 'restaurant',
                            route: { name: routeType.TAG_GROUP_LIST },
                        },
                        {
                            title: 'menu.tag',
                            icon: 'restaurant',
                            route: { name: routeType.TAG_LIST },
                        },
                    ],
                },
                {
                    title: 'menu.residential_canteens',
                    icon: 'house',
                    roles: [ROLE_ADMIN],
                    children: [
                        {
                            title: 'menu.residential_canteens',
                            icon: 'restaurant',
                            route: { name: routeType.RESIDENTIAL_CANTEENS },
                        },
                        {
                            title: 'menu.residential_canteen_relations',
                            icon: 'restaurant',
                            route: {
                                name: routeType.RESIDENTIAL_CANTEEN_RELATIONS,
                            },
                        },
                        {
                            title: 'menu.residential_canteen_addresses',
                            icon: 'restaurant',
                            route: {
                                name: routeType.RESIDENTIAL_CANTEEN_ADDRESSES,
                            },
                        },
                    ],
                },
                {
                    title: 'menu.registration_codes',
                    icon: 'app_registration',
                    route: {
                        name: routeType.REGISTRATION_CODES_LIST,
                    },
                },
                {
                    title: 'menu.todos',
                    icon: 'task',
                    roles: [ROLE_ADMIN],
                    route: { name: routeType.TODO_LIST },
                },
                {
                    title: 'menu.tablets.main',
                    icon: 'tablet',
                    roles: [ROLE_ADMIN],
                    route: { name: routeType.TABLETS_CONFIG },
                },
                {
                    title: 'menu.settings',
                    icon: 'settings',
                    roles: [ROLE_ADMIN],
                    children: [
                        {
                            title: 'menu.settings',
                            icon: 'settings',
                            route: { name: routeType.SETTINGS },
                        },
                        {
                            title: 'menu.restaurant_highlights',
                            icon: 'restaurant',
                            route: { name: routeType.RESTAURANT_HIGHLIGHTS },
                        },
                        {
                            title: 'menu.audit',
                            route: { name: routeType.AUDIT_LOG_LIST },
                        },
                        {
                            title: 'menu.push_notifications',
                            route: { name: routeType.PUSH_NOTIFICATIONS },
                        },
                    ],
                },
            ]
        },
    },
}
