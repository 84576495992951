<template lang="pug">
.confirm-orders(v-if="order"  ref="confirmOrders" class="is-cc")
    v-row
        v-col.mb-0( cols="12" md="6")
            v-btn(v-if="isCanteen" color="info" @click="printSummary" :disabled="disabled" block) {{ $t('orders.summary_print') }}
            print-receipt(v-else :order="order" block :small="false")
        v-col.mb-0(cols="12" md="6")
            v-btn(color="info" @click="printLabel" :disabled="disabled" block) {{ $t(isCanteen ? 'orders.print_labels' : 'orders.print_label') }}
    v-row.mt-0
        v-col.mb-0(cols="12")
            v-btn(:color="color" @click="confirm" :disabled="disabled" block) {{ $t('orders.confirm_order') }}
    slot(name="total")
</template>

<script>
import OrdersApiClient from '@/api/RestApi/OrdersApiClient'
import IdFromIri from '@/services/IdFromIri'
import orderType from '@/store/type/orderType'
import printJS from 'print-js'
import { mapGetters } from 'vuex'
import PrintReceipt from '@/components/pages/restaurantAdmin/PrintReceipt.vue'

export default {
    components: { PrintReceipt },
    props: {
        order: Object,
        value: Number,
        index: Number,
        disabled: Boolean,
    },
    computed: {
        ...mapGetters({
            loadingApprove: orderType.loaders.APPROVE_ORDER_LOADING,
        }),
        id() {
            return this.order?.id
        },
        restaurantId() {
            return IdFromIri.getId(this.order?.restaurant)
        },
        deliveryByYou() {
            return !(
                this.order?.deliveryJob &&
                this.order?.deliveryJob.primaryDeliveryMethod !==
                    'restaurant_managed'
            )
        },
        isAsap() {
            return this.order.isAsap
        },
        isCanteen() {
            return !this.order?.userProfile
        },
        ccId() {
            return (
                this.order?.cloudcanteen?.identifier ||
                this.order?.residentialcanteen?.identifier
            )
        },
        orderId() {
            return this.order?.identifier
        },
        color() {
            return this.isAsap ? 'primary' : 'warning'
        },
        cutoffTime() {
            return this.order?.canteenSuborderForCutoff
        },
    },

    methods: {
        decrease() {
            if (this.value > 10 && !(this.loadingApprove || this.disabled))
                this.$emit('input', this.value - 5)
        },
        increase() {
            this.$emit('input', this.value + 5)
        },
        confirm() {
            this.$store.dispatch(orderType.actions.APPROVE_ORDER, [
                this.id,
                this.value,
            ])
        },
        printLabel() {
            if (this.isCanteen) {
                this.printLabelCanteen()
            } else {
                this.printLabelRegular()
            }
        },
        printLabelCanteen() {
            OrdersApiClient.downloadLabel(
                this.restaurantId,
                this.ccId,
                this.cutoffTime
            )
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
        printLabelRegular() {
            OrdersApiClient.downloadLabelRegularOrder(
                this.order.id,
                this.order.token
            )
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
        printSummary() {
            OrdersApiClient.downloadSummary(this.orderId, null, this.cutoffTime)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
    },
}
</script>
