import cloneDeep from 'lodash/cloneDeep'
import {
    ROLE_ADMIN,
    ROLE_COMPANY,
    ROLE_RESTAURANT,
    ROLE_USER_PROFILE_CREATE,
} from '@/enum/roles'

class UsersFormMapper {
    toEntity(values) {
        const form = cloneDeep(values)

        form.userRoles = []

        if (form.adminOfCompany) {
            form.userRoles.push({
                company: form.adminOfCompany,
                restaurant: null,
                role: ROLE_COMPANY,
            })

            delete form.adminOfCompany
        }

        if (form.adminOfRestaurant && !Array.isArray(form.adminOfRestaurant)) {
            form.adminOfRestaurant = [form.adminOfRestaurant]
        }
        if (form.adminOfRestaurant?.length) {
            form.adminOfRestaurant.forEach((restaurant) => {
                form.userRoles.push({
                    company: null,
                    restaurant: restaurant,
                    role: ROLE_RESTAURANT,
                })
            })

            delete form.adminOfRestaurant
        }

        if (form.isSuperAdmin) {
            form.userRoles.push({
                company: null,
                restaurant: null,
                role: ROLE_ADMIN,
            })

            delete form.isSuperAdmin
        }

        if (!form.isSuperAdmin && form.extraRoles?.length) {
            form.extraRoles.forEach((extraRole) => {
                form.userRoles.push({
                    company: null,
                    restaurant: null,
                    role: extraRole,
                })
            })

            delete form.extraRoles
        }

        if (typeof form.company == 'undefined') {
            form.company = null
        }

        return form
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)
        values.extraRoles = []
        if (values.userRoles) {
            values.userRoles.forEach((role) => {
                if (role.company && role.company['@id']) {
                    values.adminOfCompany = role.company['@id']
                }

                if (role.restaurant && role.restaurant['@id']) {
                    if (values.adminOfRestaurant === undefined)
                        values.adminOfRestaurant = []
                    values.adminOfRestaurant.push(role.restaurant['@id'])
                }

                if (role.role === ROLE_ADMIN) {
                    values.isSuperAdmin = true
                }

                if (role.role === ROLE_USER_PROFILE_CREATE)
                    values.canCreateUsers = true

                if (
                    role.role !== ROLE_ADMIN &&
                    !role.company &&
                    !role.restaurant
                ) {
                    values.extraRoles.push(role.role)
                }
            })

            delete values.userRoles
        }

        values.companyTeams = values.companyTeams.map((item) => item['@id'])

        if (values.company && values.company['@id']) {
            values.companyId = values.company.id
            values.company = values.company['@id']
        }

        return values
    }
}

export default new UsersFormMapper()
