import UsersApiClient from '@/api/RestApi/UsersApiClient'
import PromotionsApiClient from '@/api/RestApi/PromotionsApiClient'
import ApiClientType from '@/api/RestApiType'
import CanteensApiClient from '@/api/RestApi/CanteensApiClient'
import CompaniesApiClient from '@/api/RestApi/CompaniesApiClient'
import CompanyAddresses from '@/api/RestApi/CompanyAddresses'
import CompanyAllowances from '@/api/RestApi/CompanyAllowances'
import CompanyCloudcanteens from '@/api/RestApi/CompanyCloudcanteens'
import CompanyBalances from '@/api/RestApi/CompanyBalances'
import CompanyInvoice from '@/api/RestApi/CompanyInvoice'
import UserAddresses from '@/api/RestApi/UserAddresses'
import OrdersApiClient from '@/api/RestApi/OrdersApiClient'
import OrdersPublicApiClient from '@/api/RestApi/OrdersPublicApiClient'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import MealCategoriesApiClient from '@/api/RestApi/MealCategoriesApiClient'
import MealsApiClient from '@/api/RestApi/MealsApiClient'
import MealOptionGroups from '@/api/RestApi/MealOptionGroups'
import MealOptions from '@/api/RestApi/MealOptions'
import TranslationsApiClient from '@/api/RestApi/TranslationsApiClient'
import DeliverySettings from '@/api/RestApi/DeliverySettings'
import RestaurantOrderCanteensApiClient from '@/api/RestApi/RestaurantOrderCanteensApiClient'
import TagsApiClient from '@/api/RestApi/TagsApiClient'
import TagGroupsApiClient from '@/api/RestApi/TagGroupsApiClient'
import NotificationsApiClient from '@/api/RestApi/NotificationsApiClient'
import DeliveriesApiClient from '@/api/RestApi/DeliveriesApiClient'
import ResidentialCanteensApiClient from '@/api/RestApi/ResidentialCanteensApiClient'
import ResidentialCanteenAddressesApiClient from '@/api/RestApi/ResidentialCanteenAddressesApiClient'
import ResidentialCanteenRelationsApiClient from '@/api/RestApi/ResidentialCanteenRelationsApiClient'
import AuditLogApiClient from '@/api/RestApi/AuditLogApiClient'
import CustomExportApiClient from '@/api/RestApi/CustomExportApiClient'
import CouponApiClient from '@/api/RestApi/CouponApiClient'
import InvoicesApiClient from '@/api/RestApi/InvoicesApiClient'
import CompanyTeams from '@/api/RestApi/CompanyTeams'
import SettingsApiClient from '@/api/RestApi/SettingsApiClient'
import RegistrationCodesApiClient from '@/api/RestApi/RegistrationCodesApiClient'
import PromoBannersApiClient from '@/api/RestApi/PromoBannersApiClient'
import TodoApiClient from '@/api/RestApi/TodoApiClient'
import CustomExportCategoryClient from '@/api/RestApi/CustomExportCategoryApiClient'
import BrandsApiClient from '@/api/RestApi/BrandsApiClient'
import OrderHistoryApiClient from '@/api/RestApi/OrderHistoryApiClient'

class RestApiCollection {
    get(name) {
        switch (name) {
            case ApiClientType.USERS:
                return UsersApiClient
            case ApiClientType.PROMOTIONS:
                return PromotionsApiClient
            case ApiClientType.CANTEENS:
                return CanteensApiClient
            case ApiClientType.COMPANIES:
                return CompaniesApiClient
            case ApiClientType.COMPANY_ADDRESSES:
                return CompanyAddresses
            case ApiClientType.COMPANY_ALLOWANCES:
                return CompanyAllowances
            case ApiClientType.COMPANY_CLOUDCANTEENS:
                return CompanyCloudcanteens
            case ApiClientType.COMPANY_TEAMS:
                return CompanyTeams
            case ApiClientType.COMPANY_BALANCES:
                return CompanyBalances
            case ApiClientType.COMPANY_INVOICE:
                return CompanyInvoice
            case ApiClientType.USER_ADDRESSES:
                return UserAddresses
            case ApiClientType.ORDERS:
                return OrdersApiClient
            case ApiClientType.ORDERS_PUBLIC:
                return OrdersPublicApiClient
            case ApiClientType.RESTAURANT_ORDER_CANTEENS:
                return RestaurantOrderCanteensApiClient
            case ApiClientType.RESTAURANTS:
                return RestaurantsApiClient
            case ApiClientType.MEAL_CATEGORIES:
                return MealCategoriesApiClient
            case ApiClientType.MEAL:
                return MealsApiClient
            case ApiClientType.MEAL_OPTION_GROUP:
                return MealOptionGroups
            case ApiClientType.MEAL_OPTION:
                return MealOptions
            case ApiClientType.TRANSLATIONS:
                return TranslationsApiClient
            case ApiClientType.DELIVERY_SETTINGS:
                return DeliverySettings
            case ApiClientType.TAGS:
                return TagsApiClient
            case ApiClientType.TAG_GROUPS:
                return TagGroupsApiClient
            case ApiClientType.NOTIFICATION:
                return NotificationsApiClient
            case ApiClientType.DELIVERIES:
                return DeliveriesApiClient
            case ApiClientType.RESIDENTIAL_CANTEENS:
                return ResidentialCanteensApiClient
            case ApiClientType.RESIDENTIAL_CANTEEN_ADDRESSES:
                return ResidentialCanteenAddressesApiClient
            case ApiClientType.RESIDENTIAL_CANTEEN_RELATIONS:
                return ResidentialCanteenRelationsApiClient
            case ApiClientType.AUDITLOG:
                return AuditLogApiClient
            case ApiClientType.CUSTOM_EXPORT:
                return CustomExportApiClient
            case ApiClientType.COUPONS:
                return CouponApiClient
            case ApiClientType.INVOICES:
                return InvoicesApiClient
            case ApiClientType.SETTINGS:
                return SettingsApiClient
            case ApiClientType.REGISTRATION_CODES:
                return RegistrationCodesApiClient
            case ApiClientType.PROMO_BANNERS:
                return PromoBannersApiClient
            case ApiClientType.TODOS:
                return TodoApiClient
            case ApiClientType.CUSTOM_EXPORT_CATEGORY:
                return CustomExportCategoryClient
            case ApiClientType.BRANDS:
                return BrandsApiClient
            case ApiClientType.ORDER_HISTORY:
                return OrderHistoryApiClient
        }

        return null
    }
}

export default new RestApiCollection()
