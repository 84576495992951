import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

export default [
    // SETTINGS
    {
        path: '/settings',
        name: routeType.SETTINGS,
        component: () =>
            import(/* webpackChunkName: "settings" */ '@/pages/settings/Edit'),
        meta,
    },
    // RESTAURANT HIGHLIGHTS
    {
        path: '/restaurant-highlights',
        name: routeType.RESTAURANT_HIGHLIGHTS,
        component: () =>
            import(
                /* webpackChunkName: "settings" */ '@/pages/settings/RestaurantHighlights'
            ),
        meta,
    },
    // SEND PUSH NOTIFICATIONS
    {
        path: '/push-notifications',
        name: routeType.PUSH_NOTIFICATIONS,
        component: () =>
            import(
                /* webpackChunkName: "settings" */ '@/pages/settings/PushNotifications.vue'
            ),
        meta,
    },
]
