var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[(_vm.isSuperAdmin)?_c('div',{staticClass:"pr-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm._f("lowercase")(_vm.item.deliveryAddressDetails.city),"small":""}},on),[_vm._v("circle")])]}}],null,false,3963746554)},[_c('span',[_vm._v(_vm._s(_vm.item.deliveryAddressDetails.city))])])],1):_vm._e(),_c('div',[(_vm.item.userProfile)?_c('router-link',{attrs:{"to":_vm.getIdFromIri(
                    _vm.item.userProfile,
                    '@id',
                    _vm.routeType.USER_EDIT,
                    'userId'
                )}},[_vm._v(" "+_vm._s(_vm._f("toFullName")(_vm.item.userProfile))+" ")]):[_vm._v(" "+_vm._s(_vm.item.deliveryName)+" ")],(
                !_vm.isCompanyAdmin &&
                _vm.item.companyName &&
                _vm.item.companyName !== _vm.item.deliveryName
            )?_c('small',{staticClass:"d-flex g-73--text"},[_vm._v(" "+_vm._s(_vm.item.companyName)+" ")]):_vm._e(),(!_vm.item.userProfile)?_c('small',{staticClass:"d-flex g-73--text"},[_vm._v(" "+_vm._s(_vm.$t('orders.canteen_details', { portion: _vm.meals, customer: _vm.customers, }))+" ")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }