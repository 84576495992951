import { render, staticRenderFns } from "./ActiveOrder.vue?vue&type=template&id=2300a38e&scoped=true&lang=pug&"
import script from "./ActiveOrder.vue?vue&type=script&lang=js&"
export * from "./ActiveOrder.vue?vue&type=script&lang=js&"
import style0 from "./ActiveOrder.vue?vue&type=style&index=0&id=2300a38e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2300a38e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCard,VCardText,VChip,VCol,VDivider,VFadeTransition,VIcon,VProgressCircular,VRow})
