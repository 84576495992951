<template>
    <v-form ref="form" v-model="isValid" autocomplete="off">
        <v-alert v-model="hasFormErrors" color="error" class="mb-2" dismissible>
            <span v-for="(item, index) in formErrors" :key="`error-${index}`"
                >{{ item }}<br
            /></span>
        </v-alert>

        <template v-if="modalMode">
            <h2>{{ headTitle }}</h2>
            <hr />
        </template>

        <page-title v-else-if="!noPageTitle">{{ headTitle }}</page-title>

        <overlay :show="busy" :progress="showProgress">
            <template v-if="modalMode">
                <slot></slot>
            </template>
            <template v-else>
                <slot></slot>

                <portal to="footer">
                    <v-btn
                        v-if="!disableSaveButton"
                        color="primary"
                        class="white--text"
                        :disabled="disabled || !isValid"
                        @click="submit"
                    >
                        {{ $t('actions.save') }}
                    </v-btn>

                    <delete-button
                        v-if="entityId && !disableDeleteButton"
                        class="ml-3"
                        :disabled="busy"
                        @onDelete="onDelete"
                    ></delete-button>
                </portal>
            </template>
        </overlay>
        <confirm-modal
            :value="dialog"
            :text="$t('texts.unsaved_sure')"
            :approve-text="$t('actions.back')"
            :cancel-text="$t('actions.leave')"
            @onApprove="back"
            @onCancel="leavePage"
        ></confirm-modal>
    </v-form>
</template>

<script>
import isEqual from 'lodash/isEqual'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { LABEL_PH } from '@/defaults'
import dirtyType from '@/store/type/dirtyType'
import baseFormType from '@/store/type/baseFormType'
import MapperType from '@/services/mapper/MapperType'
import PageTitle from '@/components/layout/components/pageTitle'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import Overlay from '@/components/overlay/Overlay'
import DeleteButton from './Actions/DeleteButton'

export default {
    components: {
        PageTitle,
        DeleteButton,
        ConfirmModal,
        Overlay,
    },
    props: {
        entityId: {
            type: [Number, String],
            default: null,
        },
        value: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        mapper: {
            type: String,
            default: MapperType.DEFAULT_FORM,
        },
        api: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
        },
        disableDeleteButton: {
            type: Boolean,
        },
        disableCancelButton: {
            type: Boolean,
        },
        modalMode: {
            type: Boolean,
        },
        disableSaveButton: {
            type: Boolean,
        },
        showProgress: {
            type: Boolean,
            default: true,
        },
        noPageTitle: Boolean,
        preDeleteHook: {
            type: Function,
            required: false,
        },
    },
    inject: {
        $validator: 'formValidator',
    },
    data() {
        return {
            isValid: false,
        }
    },
    computed: {
        ...mapGetters({
            model: `baseForm/${baseFormType.getters.MODEL}`,
            hasFormErrors: `baseForm/${baseFormType.getters.HAS_FORM_ERRORS}`,
            savedFormdata: `baseForm/${baseFormType.getters.SAVED_DATA}`,
        }),
        ...mapState({
            dialog: (state) => state.dirty.dialog,
            nextPage: (state) => state.dirty.nextPage,
            deliveryDialog: (state) => state.dirty.deliveryDialog,
        }),
        busy: {
            get() {
                return this.$store.getters[
                    `baseForm/${baseFormType.getters.BUSY}`
                ]
            },
            set(val) {
                this.$store.commit(
                    `baseForm/${baseFormType.mutations.SET_BUSY}`,
                    val
                )
            },
        },
        formErrors: {
            get() {
                return this.$store.getters[
                    `baseForm/${baseFormType.getters.FORM_ERRORS}`
                ]
            },
            set(val) {
                this.$store.commit(
                    `baseForm/${baseFormType.mutations.ADD_FORM_ERROR}`,
                    val
                )
            },
        },
        hasFormErrors: {
            get() {
                return this.$store.getters[
                    `baseForm/${baseFormType.getters.HAS_FORM_ERRORS}`
                ]
            },
            set(val) {
                this.$store.commit(
                    `baseForm/${baseFormType.mutations.HAS_ERROR}`,
                    val
                )
            },
        },
        headTitle() {
            return this.title ?? LABEL_PH
        },
    },
    watch: {
        disabled(disabled) {
            this.busy = disabled
        },
        entityId: {
            immediate: true,
            handler(entityId) {
                if (entityId == null) return

                this.load()
            },
        },
        isValid: {
            immediate: true,
            handler(isValid) {
                this.$emit('valid', isValid)
            },
        },
        value: {
            handler() {
                let dirty = !isEqual(this.value, this.savedFormdata)
                this.setDirty(dirty)
            },
            deep: true,
        },
        model: {
            deep: true,
            handler(modelNew, modelOld) {
                if (
                    !(
                        isEqual(modelNew, modelOld) &&
                        isEqual(modelNew, this.value)
                    )
                ) {
                    this.$emit('input', modelNew)
                    if (this.value.id === modelNew.id && modelNew.id)
                        this.$emit('updated')
                }
            },
        },
    },

    mounted() {
        this.setValidator({ validator: this.$validator, errors: this.errors })
    },

    methods: {
        ...mapActions({
            loadModel: `baseForm/${baseFormType.actions.LOAD}`,
            saveModel: `baseForm/${baseFormType.actions.SAVE}`,
            deleteModel: `baseForm/${baseFormType.actions.DELETE}`,
        }),
        ...mapMutations({
            setDialog: dirtyType.mutations.SET_DIALOG,
            setDirty: dirtyType.mutations.SET_DIRTY,
            setNextPage: dirtyType.mutations.SET_NEXT_PAGE,
            setCancelDeliveryDialog:
                dirtyType.mutations.SET_CANCELDELIVERYDIALOG,
            setValidator: `baseForm/${baseFormType.mutations.SET_VALIDATOR}`,
            resetFormErrors: `baseForm/${baseFormType.mutations.RESET_FORM_ERRORS}`,
        }),

        submit() {
            this.busy = true
            // wait 1 sec to ensure all changes came into effect
            setTimeout(() => {
                this.$validator.validate().then((isValid) => {
                    if (!isValid) {
                        this.busy = false
                        return
                    }
                    this.save()
                })
            }, 1000)
        },
        save() {
            this.saveModel({
                model: this.value,
                api: this.api,
                id: this.entityId,
                mapper: this.mapper,
            })
                .then((response) => {
                    this.setDirty(false)
                    if (!this.entityId) {
                        this.$emit('created', response)
                    }
                })
                .catch(() => {
                    this.$emit('error')
                })
        },
        load() {
            if (this.entityId == null) return
            this.loadModel({
                api: this.api,
                id: this.entityId,
                mapper: this.mapper,
            }).catch(() => {
                this.$emit('error')
            })
        },
        async onDelete() {
            if (this.preDeleteHook) {
                const isSuccess = await this.preDeleteHook()
                if (!isSuccess) {
                    return false
                }
            }

            this.deleteModel({
                api: this.api,
                entity: this.value,
            }).then(() => {
                // this.load() // WTF was this for?
                this.$emit('deleted')
            })
        },
        reset() {
            this.$refs.form.reset()
        },
        back() {
            this.setDialog(false)
        },
        leavePage() {
            const nextPage = this.nextPage
            this.setNextPage(null)
            this.setDirty(false)
            this.setDialog(false)
            this.$router.push(nextPage)
        },
    },
}
</script>
