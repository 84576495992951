import Notification from '@/services/Notification/Notification'
import Store from '@/store/store'

class ResponseHandler {
    handle(request, mutation, mapper) {
        return request
            .then((response) => {
                if (
                    response.data &&
                    typeof response.data === 'object' &&
                    'success' in response.data &&
                    !response.data.success
                ) {
                    if (response.data.message)
                        Notification.warning(response.data.message)
                } else {
                    if (mutation) {
                        const data = mapper
                            ? mapper.fromEntity(response.data || response)
                            : response['hydra:member'] ||
                              response.data ||
                              response
                        Store.commit(mutation, data)
                    }
                }
                return Promise.resolve(response.data || response)
            })
            .catch((reason) => {
                if (reason?.message === 'canceled') {
                    return Promise.resolve(reason)
                }

                if (
                    !reason.response ||
                    reason.response.status >= 500 ||
                    reason.response.data?.errors?.message
                ) {
                    Notification.requestError(
                        reason.response?.data?.errors || reason
                    )
                }

                return Promise.reject(reason)
            })
    }
}

export default new ResponseHandler()
