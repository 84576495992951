export default {
    props: {
        label: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        rawLabel: Boolean,
    },

    computed: {
        labelText() {
            return this.rawLabel ? this.label : this.$t(this.label)
        },
    },
}
